<template>
  <div class="commercial-ioan-detailed">
    <Tabs
      link="/MonthlyPaymentDetail"
      @select-tab="selectTab"
      :option="option"
    ></Tabs>
    <Card class="card">
      <div class="card-body">
        <ul
          class="card-items-group"
          :class="{ 'mb-0': $route.query.cal === '按贷款总额' }"
          v-show="type === '等额本息'"
        >
          <li class="card-item" v-if="calMethod !== '按贷款总额'">
            <span class="item-label">首付款：</span>
            <span class="item-value">{{ epi.paymentPrice | keepTwoDecimalFull }}万</span>
          </li>
          <li class="card-item">
            <span class="item-label">贷款总额：</span>
            <span class="item-value"
              >{{ (Number(epi.totalPrincipal) / 10000).toFixed(2) }} 万</span
            >
          </li>
          <li class="card-item">
            <span class="item-label">利息总额：</span>
            <span class="item-value"
              >{{ (Number(epi.totalInter) / 10000).toFixed(2) }} 万</span
            >
          </li>
          <li class="card-item">
            <span class="item-label">贷款年限：</span>
            <span class="item-value">{{ epi.totalYear }} 年</span>
          </li>
          <li class="card-item">
            <span class="item-label">月还款额：</span>
            <span class="item-value">{{ epi.epiFirstMonth.mPay }}元</span>
          </li>
        </ul>

        <ul class="card-items-group" v-show="type === '等额本金'">
          <li class="card-item" v-if="calMethod !== '按贷款总额'">
            <span class="item-label">首付款：</span>
            <span class="item-value">{{ epi.paymentPrice | keepTwoDecimalFull }}万</span>
          </li>
          <li class="card-item">
            <span class="item-label">贷款总额：</span>
            <span class="item-value"
              >{{ (Number(ep.totalPrincipal) / 10000).toFixed(2) }} 万</span
            >
          </li>
          <li class="card-item">
            <span class="item-label">利息总额：</span>
            <span class="item-value"
              >{{ (Number(ep.totalInter) / 10000).toFixed(2) }} 万</span
            >
          </li>
          <li class="card-item">
            <span class="item-label">贷款年限：</span>
            <span class="item-value">{{ ep.totalYear }} 年</span>
          </li>
          <li class="card-item">
            <span class="item-label">首月还款：</span>
            <span class="item-value">{{ ep.epFirstMonth.mPay }}元</span>
          </li>
          <li class="card-item">
            <span class="item-label">末月还款：</span>
            <span class="item-value">{{ ep.epLastMonth.mPay }}元</span>
          </li>
          <li class="card-item w-100" @click="showDefinitePicker = true">
            <span class="item-label">还款明细：</span>
            <span class="item-value">{{ this.columns[0].value }} 每月递减{{ parseFloat(this.columns[0].diff_value).toFixed(2) }}</span>
            <img
              src="../assets/image/more.png"
              alt=""
              width="15"
              height="15"
              style="vertical-align: middle; float: right"
            />
          </li>
        </ul>
        <button class="re-cal-btn" @click="handleCalculat">重新计算</button>
      </div>
    </Card>
    <Alert
      title="温馨提示 : 结果仅供参考，具体数额以实际缴纳为准。"
      class="alert"
    ></Alert>

    <van-popup
      v-model="showDefinitePicker"
      position="bottom"
      @close="onDefinitePickerCancel"
    >
      <div class="popup-title">
        <span>贷款明细</span>
      </div>
      <!-- <van-picker title="还款明细" :columns="columns" /> -->
      <div
        class="data-list"
      >
        <p v-for="(item, index) in columns" :key="index" class="list-item">
          <span>{{item.name}}</span>
          <span>{{item.value}}</span>
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Tabs from "../components/Tabs/index";
import Card from "../components/Card/index";
import Alert from "../components/Alert/index";

export default {
  name: "CommercialIoanDetailed",
  components: {
    Tabs,
    Card,
    Alert,
  },
  created() {
    this.epi = this.$store.state.calcuRes.epi;
    this.ep = this.$store.state.calcuRes.ep;
    this.columns = this.$store.state.calcuRes.ep.totalDetail
  },
  data() {
    return {
      type: "等额本息",
      interest: 40,
      loan: 60,
      showDefinitePicker: false,
      columns: [],
      epi: {
        // 等额本息
        totalInter: "", // 总利息
        totalPrincipal: "",
      },
      ep: {}, //  等额本金
    };
  },
  computed: {
    calMethod() {
      return this.$route.query.cal;
    },
    option() {
      let interest = null;
      let loan = null;
      if (this.type === "等额本息") {
        // 贷款总额占比
        loan = Number((
          (Number(this.epi.totalPrincipal) /
            (Number(this.epi.totalPrincipal) + Number(this.epi.totalInter))) *
          100
        ).toFixed());
        interest = 100 - Number(loan);
        return [
          {
            type: "等额本息",
            name: "利息总额",
            percent: interest,
          },
          {
            type: "等额本息",
            name: "贷款总额",
            percent: loan,
          },
        ];
      } else {
        loan = Number((
          (Number(this.ep.totalPrincipal) /
            (Number(this.ep.totalPrincipal) + Number(this.ep.totalInter))) *
          100
        ).toFixed());
        interest = 100 - Number(loan);
        return [
          {
            type: "等额本金",
            name: "利息总额",
            percent: interest,
          },
          {
            type: "等额本金",
            name: "贷款总额",
            percent: loan,
          },
        ];
      }
    },
  },
  methods: {
    selectTab(type, eleType) {
      this.type = type;
      // 标签的按钮埋点
      if(eleType !== 'img') {
        window.gio('track', 'loanresultclick', {
          "module_var": "标签",
          "buttonname_var": type,
          "userid_var": this.$store.state.userId
        });
      }
    },
    onDefinitePickerCancel() {},
    handleCalculat() {
      // 重新计算的按钮埋点
      window.gio('track', 'loanresultclick', {
        "module_var": "重新计算",
        "buttonname_var": "重新计算",
        "userid_var": this.$store.state.userId
      });

      if (this.$route.query.type === "busness") {
        this.$router.push(
          "/?cal=" + this.$route.query.cal
        );
      } else if (this.$route.query.type === "pubaccu") {
        this.$router.push(
          "/pubaccu?cal=" + this.$route.query.cal
        );
      } else {
        this.$router.push(
          "/composition?cal=" + this.$route.query.cal
        );
      }
    },
  },
};
</script>

<style scoped>
.commercial-ioan-detailed {
  padding-top: 40px;
}
.card {
  margin: 24px auto;
}
.card-body {
  width: 100%;
}
.card-items-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 64px;
}
.mb-0 {
  margin-bottom: 0;
}
.card-item {
  width: 50%;
  margin-bottom: 56px;
}
.w-100 {
  width: 100%;
}
.card-items-group > li:last-child {
  margin-bottom: 0;
}
.item-label {
  font-size: 28px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
.item-value {
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
}
.re-cal-btn {
  display: block;
  width: 100%;
  height: 96px;
  background: #ffcc33;
  border-radius: 16px;
  color: #333333;
  font-size: 28px;
  line-height: 96px;
  text-align: center;
  border: none;
  outline: none;
  font-weight: bold;
}
.alert {
  margin: 0 auto;
}
.popup-title {
  width: 670px;
  height: 112px;
  border-bottom: 2px solid #eeeeee;
  line-height: 112px;
  font-size: 32px;
  color: #333;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.data-list {
  padding-top: 30px;
  height: 680px;
  overflow: scroll;
}
.list-item {
  line-height: 90px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item span:first-child {
  margin-right: 50px;
}
</style>
