<template>
  <div class="tabs-wrap">
    <div class="tabs-content">
      <ul class="nav">
        <li class="item" :class="{ active: principalAndInterest }" @click="(e) => selectMethod(e, '等额本息')">
          <span>等额本息</span>
          <img
            class="help-btn"
            src="../../assets/image/help.png"
            alt=""
            type="img"
          />
        </li>
        <li class="item" :class="{ active: equalPrincipal }" @click="(e) => selectMethod(e, '等额本金')">
          <span>等额本金</span>
          <img
            class="help-btn"
            src="../../assets/image/help.png"
            alt=""
            type="img"
          />
        </li>
      </ul>
      <!-- 图表.... -->
      <div class="tab-body">
        <div style="position: relative">
          <div id="charts">
            <canvas id="f2-charts"></canvas>
          </div>
        </div>
        <p class="check-link">
          <span style="color: #3366ff" @click="() => handleLink('详细月供')">查看详细月供 ></span>
        </p>
      </div>
    </div>

    <!-- 模态框 -->
    <van-dialog v-model="showModal" :show-confirm-button="false">
      <div class="modal-content">
        <div style="overflow-y: scroll; height: 100%; overflow-x: hidden">
          <div class="content-item">
            <h2 class="content-item-title">等额本息</h2>
            <p class="content-item-body">
              每月还款不变，其中本金逐渐增加，利息逐渐减少。
            </p>
          </div>
          <div class="content-item">
            <h2 class="content-item-title">等额本金</h2>
            <p class="content-item-body">
              每月还款金额不同，首月金额最多，之后每月递减，本金不变利息逐渐减少。
            </p>
          </div>
          <div class="content-item">
            <h2 class="content-item-title">等额本息和等额本金的区别</h2>
            <p class="content-item-body">
              1、两者“每月还款总金额”有明显区别：等额本息，就是每个月还款的“本+息”之和始终保持不变。而等额本金，就是每月的还款总额里，本金部分始终保持不变，利息另算。例如，借了50万元，要分50个月还，则按等额本金法，每月要固定还1万元本金，另加利息。
            </p>
            <p class="content-item-body">
              2、两者因还款方式不同而形成计算思路的不同：等额本息的计算思路是：假定这个月还了“本+息”共Y元钱，那剩余的欠款“本+息”，在下一个月里又同样还了Y元，如此这般，到了最后还款期限满，欠款“本+息”应该等于零。这样，经过数学推导和演算，求解出Y值来。
            </p>
          </div>
        </div>
        <van-button 
            round block 
            @click="showModal = false"
            class="close-modal-btn"
          >关闭</van-button>
      </div>
      <!-- <img
        src="../../assets/image/close-modal.png"
        alt=""
        class="close-modal-btn"
        @click="showModal = false"
      /> -->
    </van-dialog>
  </div>
</template>

<script>
import { cityData } from "../../utils/data";
const F2 = require("@antv/f2");

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    option: {
      type: Array,
      default: () => [
        {
          name: "利息总额",
          percent: 40,
          a: "1",
        },
        {
          name: "贷款总额",
          percent: 60,
          a: "1",
        },
      ],
    },
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.renderCahrts();
          document.getElementById("chart-desc").onclick = () => {
            this.handleLink('月供');
          };
        }, 100);
      }
    },
  },
  mounted() {
    this.epi = this.$store.state.calcuRes.epi;
    this.ep = this.$store.state.calcuRes.ep;
    setTimeout(() => {
      this.renderCahrts();
      document.getElementById("chart-desc").onclick = () => {
        this.handleLink('月供');
      };
    }, 1000);
  },
  data() {
    return {
      cityData,
      showModal: false,
      principalAndInterest: true,
      equalPrincipal: false,
      chart: null,
      type: 'epi'
    };
  },
  methods: {
    renderCahrts() {
      var map = {};
      this.option.map(function (obj) {
        map[obj.name] = obj.percent + "%";
      });
      var chart = new F2.Chart({
        id: "f2-charts",
        pixelRatio: window.devicePixelRatio,
        padding: [0, "auto"],
      });
      chart.source(this.option, {
        percent: {
          formatter: function formatter(val) {
            return val + "%";
          },
        },
      });
      chart.tooltip(false);
      chart.legend({
        position: "right",
        itemFormatter: function itemFormatter(val) {
          return val + "    " + map[val];
        },
      });
      chart.coord("polar", {
        transposed: true,
        innerRadius: 0.7,
        radius: 0.85,
      });
      chart.axis(false);
      chart
        .interval()
        .position("a*percent")
        .color("name", ["#FE5D4D", "#3BA4FF"])
        .adjust("stack");

      chart.guide().html({
        position: ["50%", "50%"],
        html:
          `<div style="width: 250px;height: 40px;text-align: center;top:150px;" id="chart-desc">
          <div class="chart-desc-title">月供</div>
          <div class="chart-desc">${this.option[0].type === '等额本息' ? this.epi.epiFirstMonth.mPay : this.ep.epFirstMonth.mPay}元</div>
          </div>`,
      });
      // 检查当前是否存在辅助html
      let guideWapper = document.getElementsByClassName('guideWapper');
      if(!!(guideWapper.length)) {
        for(let i = 0; i < guideWapper.length; i++) {
          guideWapper[i].remove();
        }
      }
      chart.render();
    },
    handleLink(linktype) {
      window.gio('track', 'loanresultclick', {
        "module_var": "环形图",
        "buttonname_var": linktype,
        "userid_var": this.$store.state.userId
      });
      this.$router.push(`/MonthlyPaymentDetail?cal=${this.$route.query.cal}&type=${this.type}&mode=${this.$route.query.type}`);
    },
    selectMethod(event, methodFlag) {
      if (methodFlag === "等额本息") {
        this.principalAndInterest = true;
        this.equalPrincipal = false;
        this.type = 'epi';
      } else if (methodFlag === "等额本金") {
        this.principalAndInterest = false;
        this.equalPrincipal = true;
        this.type = 'ep';
      }
      console.log('event----->', event);
      const eleType =  event.srcElement.getAttribute("type");
      if (methodFlag) {
        this.$emit("select-tab", methodFlag, eleType);
      }

      if (event.srcElement.getAttribute("type") === "img") {
        // 弹出modal
        this.showModal = true;
      }
    },
  },
};
</script>

<style>
.modal-content {
  padding: 64px 50px;
  background-color: #ffffff;
  padding-bottom: 160px;
  border-radius: 16px;
  height: 843px;
  box-sizing: border-box;
  position: relative;
}
.modal-content .content-item {
  margin-bottom: 40px;
}
.modal-content .content-item-title {
  color: #292b33;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}
.modal-content .content-item-body {
  color: #333;
  font-size: 26px;
  line-height: 36px;
  text-align: justify;
}
.close-modal-btn {
  position: absolute !important;
  margin: auto !important;
  width: 80% !important;
  left: 0;
  right: 0;
  bottom: 40px;
  background-color: #ffcc33 !important;
}
.tabs-content {
  width: 686px;
  margin: 0 auto;
}
.tabs-wrap /deep/ .van-dialog {
  overflow: inherit;
}
.nav {
  display: flex;
  width: 625px;
  position: relative;
  left: 30px;
  align-items: flex-end;
}
* {
  box-sizing: border-box;
}
.nav .item {
  flex: 1;
  height: 76px;
  background: #eee;
  border-radius: 16px 16px 0 0;
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav .item span {
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  color: #999999;
}
.nav .item.active span {
  color: #333;
}
.nav .item.active {
  height: 94px;
  background: #ffffff;
  z-index: 10;
}
.nav .item:before,
.nav .item:after {
  content: "";
  width: 30px;
  height: 100%;
  position: absolute;
  background: #eee;
}
.nav .item:before {
  right: -15px;
  border-radius: 0 10px 0 0;
  transform: skew(20deg);
}
.nav .item:after {
  left: -15px;
  border-radius: 10px 0 0 0;
  transform: skew(-20deg);
}

.nav .item.active:before,
.nav .item.active:after {
  background: #ffffff;
  z-index: 10;
}
.nav .item.active:before {
  border-left: none;
}
.nav .item.active:after {
  border-right: none;
}
.tab-body {
  width: 686px;
  height: 428px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.help-btn {
  width: 25.97px;
  height: 25.97px;
}
.chart-text {
  position: absolute;
  margin: auto;
  left: 20px;
  right: 30px;
  top: 20px;
  bottom: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.chart-name {
  margin-left: 3px;
  margin-right: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
}
.chart-percentage {
  font-size: 28px;
  font-weight: normal;
  color: #292b33;
}
.check-link {
  position: absolute;
  right: 58px;
  bottom: 64px;
  font-size: 24px;
  font-weight: normal;
  color: #3366ff;
}
</style>