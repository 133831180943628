<template>
  <div class="alert-wrap">
    <img class="status-icon" src="../../assets/image/info-icon.png" alt="">
    <p class="alert-title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.status-icon {
  width: 30px;
  height: 30px;
  margin-right: 14px;
}
.alert-wrap {
  width: 100%;
  max-width: 686px;
  min-height: 72px;
  background: #FFF9F7;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  padding: 23px 34px;
  align-items: center;
}
.alert-wrap p {
  color: #FF6633;
  font-size: 20px;
  line-height: 26px;
}
</style>