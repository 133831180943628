<template>
  <div class="card-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.card-wrap {
  width: 686px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px rgba(240, 240, 240, 0.25);
  padding: 48px 32px;
}
</style>